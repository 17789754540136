const colors = {
  primary: "#e20074",
  darkprimary: "#cb0068",
  dark: "#262626",
  light: "#fff",
  lightgrey: "#b2b2b2",
  navigationGrey: "#dcdcdc",
  backgroundBlue: "#f8f8f8",
  darkgrey: "#6b6b6b",
  grey: "#eee",
  red: "red",
  textBlue: "#00739F",
  blue: "#235482", // weg
  warning: "#FF9A1E",
  success: "#559a08",
  highlightText: "#218076",
}

export default colors
