const zIndex = {
  subway: "-1",
  car: "1",
  drone: "100",
  helicopter: "200",
  plane: "300",
  spaceship: "400",
}

export default zIndex
