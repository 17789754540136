import React from "react"
import { Global, css } from "@emotion/core"
import { fonts, fontFamily } from "ui/fonts"

const path = "assets/fonts"
let fontImport = ""
Object.values(fonts).forEach((font) => {
  const fontPath = {
    woff: require(`../${path}/${font.file}.woff`),
    woff2: require(`../${path}/${font.file}.woff2`),
  }
  fontImport += `@font-face {
    font-family: ${font.family};
    font-weight: 300;
    font-style: normal;
    src:
      url(${fontPath.woff2}) format("woff2"),
      url(${fontPath.woff}) format("woff");
  }`
})

const globalStyling = css`
  ${fontImport}
  * {
    box-sizing: border-box;
  }
  html,
  body {
    ${fontFamily("regular")}
    font-size: 15px;
    line-height: 1.1;
    margin: 0;
  }

  /* workaround to fix the side effect of border-box that breaks the indicator size inside the global header component */
  li[class^="GlobalHeader__toolbar-item"] span[class^="Indicator__indicator"] {
    box-sizing: content-box;
  }

  .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    @media print {
      min-height: 0;
    }
  }

  main {
    flex: 1;
  }

  p {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  ol {
    margin: 0;
    padding: 0;
  }
  a {
    color: inherit;
  }

  strong,
  b {
    ${fontFamily("bold")};
    font-weight: normal;
  }

  button {
    ${fontFamily("regular")};
    font-size: 1rem;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
      margin: 0;
    }
  }

  input {
    ${fontFamily("bold")};
  }

  label {
    font-variant-ligatures: no-common-ligatures;
  }

  // DTAG COOKIE LAYER FIX:
  #dtag_privacy_prompt .privacy_prompt.explicit_consent {
    width: calc(100vw - 49px) !important;

    @media (min-width: 700px) {
      width: 640px !important;
    }
  }
`

export default () => {
  return <Global styles={globalStyling} />
}
