import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { colors, fluidFontSize, fontFamily, lineHeight, spacer } from "ui.js"

const StyledText = styled.span`
  text-align: ${(props) => (props.center ? "center" : "left")};
  color: ${colors.dark};

  @media print {
    font-size: 14px;
    line-height: ${lineHeight.tight};
  }

  ${(props) =>
    props.spacer.length > 0 &&
    css`
      ${spacer(props.spacer)};
      display: inline-block;
    `}

  ${(props) =>
    props.bold &&
    css`
      ${fontFamily("bold")}
    `}

  ${(props) =>
    props.size === "xs" &&
    css`
      ${fluidFontSize(12, 16)}
      line-height: 1.1;
    `}

  ${(props) =>
    props.size === "s" &&
    css`
      ${fluidFontSize(14, 18)}
      line-height: ${lineHeight.standard};
    `}

  ${(props) =>
    props.size === "m" &&
    css`
      ${fluidFontSize(18, 24)}
      line-height: ${lineHeight.loose};

      @media print {
        font-size: 14px;
        line-height: ${lineHeight.tight};
      }
    `}

  ${(props) =>
    props.size === "l" &&
    css`
      color: ${colors.dark};
      text-transform: uppercase;
      font-size: 18px;
      line-height: 1.2;
    `}

  ${(props) =>
    props.block &&
    css`
      display: block;
    `}

  ${(props) =>
    props.active &&
    css`
      color: blue;
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${colors[color]};
    `}
`

const Text = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <StyledText ref={ref} {...props}>
      {children}
    </StyledText>
  )
})

Text.defaultProps = {
  center: false,
  size: "s",
  active: false,
  block: false,
  spacer: [],
  children: null,
  bold: false,
  color: "",
}

Text.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  bold: PropTypes.bool,
  active: PropTypes.bool,
  center: PropTypes.bool,
  block: PropTypes.bool,
  spacer: PropTypes.arrayOf(PropTypes.number),
}

export default Text
