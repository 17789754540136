const defaultFonts = '"Helvetica Neue", "Arial", "Helvetica", sans-serif'

const fonts = {
  thin: {
    family: "Tele Neo Thin Web",
    fallback: "Tele Neo Thin",
    file: "teleneoweb-thin",
  },
  regular: {
    family: "Tele Neo Web",
    fallback: "Tele Neo",
    file: "teleneoweb-regular",
  },
  regularItalic: {
    family: "Tele Neo Italic Web",
    fallback: "Tele Neo Italic",
    file: "teleneoweb-regularitalic",
  },
  medium: {
    family: "Tele Neo Medium Web",
    fallback: "Tele Neo Medium",
    file: "teleneoweb-medium",
  },
  mediumItalic: {
    family: "Tele Neo Medium Italic Web",
    fallback: "Tele Neo Medium Italic",
    file: "teleneoweb-mediumitalic",
  },
  bold: {
    family: "Tele Neo Bold Web",
    fallback: "Tele Neo Bold",
    file: "teleneoweb-bold",
  },
  boldItalic: {
    family: "Tele Neo Bold Italic Web",
    fallback: "Tele Neo Bold Italic",
    file: "teleneoweb-bolditalic",
  },
  ultra: {
    family: "Tele Neo Bold Extra Bold Web",
    fallback: "Tele Neo Bold Extra Bold",
    file: "teleneoweb-extrabold",
  },
}

const fontFamily = (type) => {
  if (!fonts[type] || !fonts[type].family) {
    return null
  }
  return `font-family: ${fonts[type].family}, ${defaultFonts};`
}

export { fonts, fontFamily }
