const viewport = { min: 320, max: 1440 }

export const fontSize = [
  "1rem",
  "1.2rem",
  "1.6rem",
  "2.4rem",
  "3.2rem",
  "4rem",
  "4.8rem",
]

export function fluidFontSize(min, max) {
  return `
    font-size: calc(${min}px + ${max - min} * ((100vw - ${viewport.min}px) / ${
    viewport.max - viewport.min
  }));
    @media (min-width: ${viewport.max}px) {
      font-size: ${max}px;
    }
  `
}
export default fontSize
