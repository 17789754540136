import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { spacer } from "ui.js"

const StyledLoader = styled.div`
  display: block;
  position: relative;
  margin: 0 auto;

  ${(props) =>
    props.spacer.length > 0 &&
    css`
      ${spacer(props.spacer)};
    `}

  ${({ size }) =>
    size === "s" &&
    css`
      width: 20px;
      height: 20px;
    `}

  ${({ size }) =>
    size === "m" &&
    css`
      width: 80px;
      height: 80px;
    `}

  ${({ size }) =>
    size === "l" &&
    css`
      width: 140px;
      height: 140px;
    `}

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 0 auto;
    border-style: solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    ${({ color }) =>
      color &&
      css`
        border-color: ${color} transparent transparent transparent;
      `}

    ${({ size }) =>
      size === "s" &&
      css`
        width: 20px;
        height: 20px;
        border-width: 2px;
      `}

    ${({ size }) =>
      size === "m" &&
      css`
        border-width: 4px;
        width: 80px;
        height: 80px;
      `}

    ${({ size }) =>
      size === "l" &&
      css`
        border-width: 6px;
        width: 140px;
        height: 140px;
      `}
  }
  &:nth-of-type(1) {
    animation-delay: -0.45s;
  }
  &:nth-of-type(2) {
    animation-delay: -0.3s;
  }
  &:nth-of-type(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export function Loading(props) {
  return (
    <StyledLoader {...props}>
      <div />
      <div />
      <div />
    </StyledLoader>
  )
}

Loading.defaultProps = {
  size: "m",
  color: "#000",
  spacer: [],
}

Loading.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  spacer: PropTypes.arrayOf(PropTypes.number),
}
