import React from "react"
import { Loading as Loader } from "elements/loading"
import styled from "@emotion/styled"
import { colors } from "ui"

const StyledBackground = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${colors.light};
`

export function Loading() {
  return (
    <StyledBackground>
      <Loader size="l" color={colors.primary} />
    </StyledBackground>
  )
}
