import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { fontFamily, fluidFontSize, spacer, lineHeight, colors } from "ui.js"

const StyledHeadline = styled.h1`
  ${fontFamily("regular")}
  display: block;
  text-align: ${(props) => (props.center ? "center" : "left")};
  font-weight: normal;
  margin: 0;

  ${(props) =>
    props.bold &&
    css`
      ${fontFamily("bold")}
    `}

  ${(props) =>
    props.primary &&
    css`
      color: ${colors.primary};
    `}

  ${(props) =>
    props.spacer.length > 0 &&
    css`
      ${spacer(props.spacer)};
    `}

  ${(props) =>
    props.lookalike === "h1" &&
    css`
      ${fluidFontSize(36, 72)};
      margin-left: -0.06em;
    `}

  ${(props) =>
    props.lookalike === "h2" &&
    css`
      ${fluidFontSize(36, 48)};
    `}

  ${(props) =>
    props.lookalike === "h3" &&
    css`
      ${fluidFontSize(24, 36)};
      line-height: ${lineHeight.standard};

      @media print {
        font-size: 18px;
        line-height: ${lineHeight.tight};
      }
    `}

  ${(props) =>
    props.lookalike === "h4" &&
    css`
      ${fluidFontSize(18, 24)};

      @media print {
        font-size: 14px;
        line-height: ${lineHeight.tight};
      }
    `}

  ${(props) =>
    props.lookalike === "h5" &&
    css`
      ${fluidFontSize(14, 18)};
    `}
`

const Headline = React.forwardRef(({ tag, children, ...props }, ref) => {
  return (
    <StyledHeadline as={tag} ref={ref} {...props}>
      {children}
    </StyledHeadline>
  )
})

Headline.defaultProps = {
  tag: "h1",
  lookalike: "h2",
  center: false,
  bold: false,
  spacer: [],
  children: null,
  primary: false,
}

Headline.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.node,
  center: PropTypes.bool,
  primary: PropTypes.bool,
  lookalike: PropTypes.string,
  bold: PropTypes.bool,
  spacer: PropTypes.arrayOf(PropTypes.number),
}

export default Headline
