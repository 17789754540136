import React from "react"
import GlobalStyling from "ui/global"
import Headline from "elements/headline"
import Text from "elements/text"
import styled from "@emotion/styled"
import { colors } from "ui"

const StyledBackground = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: ${colors.light};
`

export const IE11Fallback = () => {
  return (
    <>
      <GlobalStyling />
      <StyledBackground>
        <div>
          <Headline spacer={[20]} primary bold center lookalike="h2" tag="h1">
            Der Telekom Shop für Ihren Kabelanschluss
          </Headline>
          <Text block center size="m">
            Der von Ihnen genutzte Browser wird leider nicht vom Telekom Shop
            für Ihren Kabelanschluss unterstützt.
          </Text>
          <Text block center size="m">
            Bitte nutzen Sie Google Chrome, Mozilla Firefox, Apple Safari oder
            Microsoft Edge.
          </Text>
        </div>
      </StyledBackground>
    </>
  )
}
