import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React, { Suspense } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Loading } from "pages/loading"
import { IE11Fallback } from "pages/ie11-fallback"
import { sentryInit } from "sentryFunctions"

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

sentryInit();

const root = createRoot(document.getElementById("root"))
if (!isIE11) {
  const App = React.lazy(() => import("./App"))
  root.render(
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  )
} else {
  // TODO: load polyfills for IE11 only if it really is IE11. So: Do not load IE11 deps when the user has modern browser
  root.render(<IE11Fallback />)
}
