export const sizes = {
  mini: 480,
  small: 640,
  medium: 768,
  large: 1170,
  huge: 1440,
  gigantic: 2000,
}

export const breakpoints = {
  onlyMini: `(max-width: ${sizes.mini - 1}px)`,
  small: `(min-width: ${sizes.small}px)`,
  onlySmall: `(min-width: ${sizes.mini}px) and (max-width: ${
    sizes.medium - 1
  }px)`,
  medium: `(min-width: ${sizes.medium}px)`,
  onlyMedium: `(min-width: ${sizes.medium}px) and (max-width: ${
    sizes.large - 1
  }px)`,
  LessThanMedium: `(max-width: ${sizes.medium - 1}px)`,
  large: `(min-width: ${sizes.large}px)`,
  onlyLarge: `(min-width: ${sizes.large}px) and (max-width: ${
    sizes.huge - 1
  }px)`,
  huge: `(min-width: ${sizes.huge}px)`,
  onlyHuge: `(min-width: ${sizes.huge}px) and (max-width: ${
    sizes.gigantic - 1
  }px)`,
  gigantic: `(min-width: ${sizes.gigantic}px)`,
  portrait: `(orientation: portrait)`,
  retina: `(min-resolution: 2dppx)`,
  tablet: `(min-width: ${sizes.medium}px) and (max-width: 1025px)`,
}

export const breakpoint = function breakpoint(name) {
  return `@media ${breakpoints[name]}`
}
