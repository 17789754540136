import * as Sentry from '@sentry/react';

export const sentryInit = () => process.env.NODE_ENV === "production" && Sentry.init({
  dsn: "https://7e5e929521361ffe91ed220c2db67def@sentry.dev.i22.de/155",
  environment: process.env.REACT_APP_CUSTOM_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.httpClientIntegration(),
  ],
  replaysOnErrorSampleRate: 1.0,
  sendDefaultPii: true
});